import React, { lazy, Suspense } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "./context/ThemeContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Spinner from "./components/Spinner";
import Layout from "./pages/Layout";

const Events = lazy(() => import("./pages/Events"));
const About = lazy(() => import("./pages/About"));
const Featured = lazy(() => import("./pages/Featured"));
const SignUp = lazy(() => import("./pages/SignUp"));
const EventDetails = lazy(() => import("./pages/EventDetails"));
const NoPage = lazy(() => import("./pages/404"));

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider>
        <BrowserRouter>
          <div className="App">
            <Layout>
              <Suspense fallback={<Spinner />}>
                <Routes>
                  <Route path="/" element={<Events />} />
                  <Route path="/home" element={<Events />} />
                  <Route path="/events" element={<Events />} />
                  <Route path="/featured" element={<Featured />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/event/:eventId" element={<EventDetails />} />
                  <Route path="*" element={<NoPage />} />
                </Routes>
              </Suspense>
            </Layout>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
