import React from "react";
import { useTheme } from "../context/ThemeContext";
import LocalBeatAppBar from "../components/LocalBeatAppBar"; // Assuming this is the correct path

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { theme } = useTheme();

  return (
    <div
      style={{
        backgroundColor: theme.backgroundColor,
        color: theme.textColor,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        // backgroundImage: theme.backgroundImage,  // Apply background image, going with just color for now, might add back in later so leaving commented
        backgroundSize: "cover", // Ensure the image covers the whole area
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <LocalBeatAppBar />
      <main
        style={{
          flex: 1,
          width: "80vw", // Set the width to 80% of the viewport width
          maxWidth: "1600px", // Optionally set a max-width for larger screens
          margin: "0 auto", // Center the content
        }}
      >
        {children}
      </main>
      <footer
        style={{
          backgroundColor: theme.secondaryColor,
          padding: "1rem",
          textAlign: "center",
        }}
      >
        <p style={{ margin: 0, color: theme.textColor }}>
          &copy; 2024 LOCALBEAT. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default Layout;
