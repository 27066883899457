import React, { createContext, useContext, useEffect, useState } from "react";
import {
  createTheme,
  CssBaseline,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material";
import { ThemeOptions } from "@mui/material/styles/createTheme";
import { debugLog } from "../util/logger";

interface Theme {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  textColor: string;
  secondaryTextColor: string;
  buttonBackgroundColor: string;
  secondaryButtonBackgroundColor: string;
  disabledButtonBackgroundColor: string;
  buttonHoverBackgroundColor: string;
  inputBackgroundColor: string;
  inputBorderColor: string;
  cardBackgroundColor: string;
  backgroundImage: string;
}

interface ThemeContextType {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

const defaultTheme: Theme = {
  primaryColor: "#FFFFFF",
  secondaryColor: "#1c1c1c",
  backgroundColor: "#101222",
  textColor: "#FFFFFF",
  secondaryTextColor: "#000000",
  buttonBackgroundColor: "#CD2026",
  secondaryButtonBackgroundColor: "#1F224F",
  disabledButtonBackgroundColor: "#555555",
  buttonHoverBackgroundColor: "#555",
  inputBackgroundColor: "#2c2c2c",
  inputBorderColor: "#444",
  cardBackgroundColor: "#333",
  backgroundImage: "url(/home_background.png)",
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  useEffect(() => {
    async function fetchTheme() {
      try {
        // Fetch theme from API or local storage in the future
        // const response = await fetch("/api/theme");
        // const data: Theme = await response.json();
        // setTheme(data);
      } catch (error) {
        debugLog("Failed to fetch theme, using default.");
      }
    }
    fetchTheme();
  }, []);

  // Create a MUI theme based on your custom theme context
  const muiTheme = createTheme({
    palette: {
      primary: {
        main: theme.primaryColor,
      },
      secondary: {
        main: theme.secondaryColor,
      },
      background: {
        default: theme.backgroundColor,
      },
      text: {
        primary: theme.secondaryTextColor,
      },
    },
    typography: {
      fontSize: 14, // Base font size

      // Responsive typography for all headings
      h1: {
        fontSize: "2.5rem",
        fontWeight: 700,
        "@media (min-width:600px)": {
          fontSize: "3rem",
        },
        "@media (min-width:960px)": {
          fontSize: "3.75rem",
        },
        "@media (min-width:1280px)": {
          fontSize: "4.5rem",
        },
      },
      h2: {
        fontSize: "2rem",
        fontWeight: 600,
        "@media (min-width:600px)": {
          fontSize: "2.5rem",
        },
        "@media (min-width:960px)": {
          fontSize: "3rem",
        },
        "@media (min-width:1280px)": {
          fontSize: "3.5rem",
        },
      },
      h3: {
        fontSize: "1.75rem",
        fontWeight: 500,
        "@media (min-width:600px)": {
          fontSize: "2rem",
        },
        "@media (min-width:960px)": {
          fontSize: "2.5rem",
        },
        "@media (min-width:1280px)": {
          fontSize: "3rem",
        },
      },
      h4: {
        fontSize: "1.5rem",
        fontWeight: 500,
        "@media (min-width:600px)": {
          fontSize: "1.75rem",
        },
        "@media (min-width:960px)": {
          fontSize: "2rem",
        },
        "@media (min-width:1280px)": {
          fontSize: "2.5rem",
        },
      },
      h5: {
        fontSize: "1.25rem",
        fontWeight: 400,
        "@media (min-width:600px)": {
          fontSize: "1.5rem",
        },
        "@media (min-width:960px)": {
          fontSize: "1.75rem",
        },
        "@media (min-width:1280px)": {
          fontSize: "2rem",
        },
      },
      h6: {
        fontSize: "1rem",
        fontWeight: 400,
        "@media (min-width:600px)": {
          fontSize: "1.25rem",
        },
        "@media (min-width:960px)": {
          fontSize: "1.5rem",
        },
        "@media (min-width:1280px)": {
          fontSize: "1.75rem",
        },
      },
      body1: {
        fontSize: "1rem",
        lineHeight: 1.5,
      },
      body2: {
        fontSize: "0.875rem",
        lineHeight: 1.43,
      },
      button: {
        textTransform: "none",
        fontWeight: 600,
      },
      caption: {
        fontSize: "0.75rem",
        lineHeight: 1.66,
      },
      overline: {
        fontSize: "0.75rem",
        textTransform: "uppercase",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: "1rem",
            margin: 0,
            padding: 0,
            backgroundColor: theme.backgroundColor,
            color: theme.secondaryTextColor,
          },
          p: {
            fontSize: "1rem",
            lineHeight: 1.5,
            marginBottom: "1rem",
          },
          h1: { marginBottom: "1.5rem" },
          h2: { marginBottom: "1.25rem" },
          h3: { marginBottom: "1rem" },
          h4: { marginBottom: "0.75rem" },
          h5: { marginBottom: "0.5rem" },
          h6: { marginBottom: "0.25rem" },
        },
      },
    },
  } as ThemeOptions);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <MUIThemeProvider theme={muiTheme}>
        <CssBaseline />{" "}
        {/* This applies baseline resets to ensure consistency */}
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
